import Portfolio from "./components/portfolio/Portfolio";
import Resume from "./components/resume/Resume";
import Topbar from "./components/topbar/Topbar";
import About from "./components/about/About";
import PostList from "./components/posts/PostList";
import Posts from "./components/posts/Posts";
import Post from "./components/posts/Post";
import NoMatch from "./components/nomatch/NoMatch";

import "./app.scss";
import { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="app">
      <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/resume" element={<Resume />} />
        <Route path="/posts" element={<Posts />}>
          <Route index element={<PostList />} />
          <Route path=":slug" element={<Post />} />
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  );
}

export default App;
